export const options: { [formatId: string]: Intl.DateTimeFormatOptions } = {
  dateTime: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  },
  date: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  },
};

export const locales = {
  'MM/DD/YYYY HH:MM': 'en-US',
  'YYYY/MM/DD HH:MM': 'en-ZA',
  'DD/MM/YYYY HH:MM': 'en-AU',
  'User Browser Setting': navigator.language,
};

export function dateFormatter(
  date: string,
  orgLocale: string,
  type: 'date' | 'dateTime',
): string {
  let res: string;

  try {
    // This because Intl.DateTimeFormat can't ingest YYYY-MM-DD without
    // sometimes altering the value of the day
    if (type === 'date' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
      date = date.replace(/-/g, '/');
    }

    const formatter = new Intl.DateTimeFormat(
      (orgLocale && locales[orgLocale]) || 'en-US',
      options[type],
    );

    res = formatter.format(new Date(date)).replace(/,/g, '');
  } catch (error) {
    console.error('dateFormatter failed due to invalid date value,', {
      date,
      orgLocale,
      type,
      error:
        error instanceof Error
          ? error.message
          : `Unknown error when attempting to format this string as a date: ${date}`,
    });

    res = date;
  }

  return res;
}
